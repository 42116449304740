import { Graph } from "./graph/Graph";
import { DeliveratorState } from "./gobjects/Deliverator.go";
import { Node } from "./graph/Node";

export function inconvenience(d: DeliveratorState, graph: Graph) {
  const direct = directDistance(d.start, d.restaurant) + directDistance(d.restaurant, d.end);

  return Math.round(d.distance - direct);
}

function directDistance(node1: Node, node2: Node) {
  return node1.position.add(node2.position.invert()).getMagnitude();
}
