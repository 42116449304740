import { Game, Scene } from "gamedeck/lib";
import { Rectangle } from "gamedeck/lib/GObjects";
import { Vector2 } from "gamedeck/lib/Utils";
import { Deliverator, DeliveratorState } from "./gobjects/Deliverator.go";
import { Map } from "./gobjects/Map.go";
import { Graph } from "./graph/Graph";
import { loadMap } from "./data/loadMap";
import deliveratorObservable from "./data/deliveratorObservable";
import graphObservable from "./data/graphObservable";
import { Camera, CameraState } from "./gobjects/Camera.go";

interface SmallMapOptions {
  deliverators: number;
}

export class DeliveratorMap extends Scene {
  deliverators: DeliveratorState[] = [];
  map: Graph = new Graph([], []);
  camera = new CameraState();

  constructor(options: SmallMapOptions) {
    super();
    this.load(options);
  }

  async load(options: SmallMapOptions) {
    this.map = await loadMap("/map-data.json");
    this.makeDeliverators(options.deliverators);
    deliveratorObservable.publish(this.deliverators.slice());
    console.log(this.map);
    graphObservable.publish(this.map);
  }

  makeDeliverators(n: number) {
    for (let i = 0; i < n; i++) {
      const deliverator = new DeliveratorState({
        position: new Vector2(Math.random() * 600, Math.random() * 600),
        id: `${i}`,
        graph: this.map
      });

      this.deliverators.push(deliverator);
      this.highlightPaths();
    }
  }

  build(game: Game) {
    return new Rectangle({
      width: game.width,
      height: game.height,
      x: 0,
      y: 0,
      color: "white",
      children: [
        new Camera(this.camera, {
          children: [
            new Map({ graph: this.map, state: { highlightedEdges: [] } }),
            ...this.deliverators.map((state) => new Deliverator(state))
          ]
        })
      ]
    });
  }

  highlightPaths() {
    this.map.edges.forEach(edge => edge.highlight = undefined);

    for (let deliverator of this.deliverators) {
      for (let edge of deliverator.edges) {
        if (edge) {
          edge.highlight = deliverator.color;
        }
      }
    }
  }

  update(game: Game) {
    this.camera.update(game)

    for (let deliverator of this.deliverators) {
      if (deliverator.segment) {
        const distance = deliverator.segmentProgress;
        const [start, end] = deliverator.segment;

        const diff = end.position.add(start.position.invert());

        const direction = Math.atan2(
          end.position.y - start.position.y,
          end.position.x - start.position.x
        );

        deliverator.position = fromMD(distance, direction).add(start.position);

        deliverator.segmentProgress += 1;

        if (deliverator.segmentProgress >= diff.getMagnitude()) {
          if (deliverator.path.length >= 1) {
            deliverator.segment = [deliverator.segment[1], deliverator.path.shift()!];
            deliverator.segmentProgress = 0;
          } else {
            deliverator.startNewPath();
            this.highlightPaths();
            deliveratorObservable.publish(this.deliverators.slice());
          }
        }
      } else if (deliverator.path.length > 0) {
        deliverator.segment = [deliverator.path.shift()!, deliverator.path.shift()!];
      }
    }
  }
}

function fromMD(magnitude: number, direction: number) {
  const x = magnitude * Math.cos(direction);
  const y = magnitude * Math.sin(direction);

  return new Vector2(x, y);
}
