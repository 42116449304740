import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import ControlPanel from "./ui/ControlPanel";
import * as serviceWorker from "./serviceWorker";
import { startGame } from "gamedeck/lib";
import { DeliveratorMap } from "./DeliveratorMap.scene";

ReactDOM.render(
  <React.StrictMode>
    <ControlPanel />
  </React.StrictMode>,
  document.getElementById("info")
);

const canvas = document.querySelector<HTMLCanvasElement>("#map")!;
const container = document.querySelector<HTMLDivElement>("#map-container")!;

canvas.width = container.getBoundingClientRect().width;
canvas.height = container.getBoundingClientRect().height;

startGame(
  new DeliveratorMap({ deliverators: 30 }),
  { canvas }
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
