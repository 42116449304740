import style, { css } from "styled-components";

export const Panel = style.section`
  background-color: #efefef;
  margin-bottom: 5px;
  padding: 7px;
`;

export const Title = style.div`
  background-color: #efefef;
  margin-bottom: 5px;
  font-size: 1.3em;
  font-weight: bold;
  display: block;
  ${props => css`color: ${props.color}`}
`;

export const Detail = style.div`
  margin-left: 15px;
  font-size: 1em;
`;
