import React from 'react';
import { DeliveratorState } from "../gobjects/Deliverator.go";
import { Panel, Title, Detail } from './common';

interface Props {
  deliverator: DeliveratorState;
}

export function PathPanel(props: Props) {
  const {distance, id, color} = props.deliverator;

  return <Panel>
    <Title color={color}>
      Deliverator {id}
    </Title>
    <Detail>
      Distance: {Math.round(distance)}
    </Detail>
  </Panel>
}
