import { Sprite } from "gamedeck/lib/Assets";
import { Ellipse } from "gamedeck/lib/assets/Ellipse";
import { Text } from "gamedeck/lib/gobjects/Text";
import { Vector2 } from "gamedeck/lib/Utils";

export enum NodeType {
  NORMAL,
  RESTAURANT,
  WAYPOINT,
}

export class Node {
  constructor(
    public id: string,
    public position: Vector2,
    public label: string,
    public group: NodeType
  ) {}

  getSprite() {
    if (this.group === NodeType.RESTAURANT) {
      return new Sprite("/spoon_and_fork.png", 25, 25, 0, 0, 512, 512);
    } else if (this.group === NodeType.NORMAL) {
      return new Ellipse(10, 10, "black");
    } else {
      return new Ellipse(0, 0, "transparent");
    }
  }

  getPositioning() {
    if (this.group === NodeType.RESTAURANT) {
      return this.position.add(new Vector2(-14, -14));
    } else if (this.group === NodeType.NORMAL) {
      return this.position.add(new Vector2(-10, -10));
    } else {
      return this.position.add(new Vector2(-10, -10));
    }
  }

  getLabel() {
    if (this.group === NodeType.RESTAURANT) {
      return new Text({
        color: "black",
        font: "10px sans-serif",
        position: new Vector2(12, 35),
        positioning: "middle center",
        text: this.label,
      });
    } else if (this.group === NodeType.NORMAL) {
      return new Text({
        color: "white",
        font: "10px sans-serif",
        position: new Vector2(10, 12),
        positioning: "middle center",
        text: this.label,
      });
    } else {
      return [];
    }
  }
}
