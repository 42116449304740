import { GObject } from "gamedeck/lib/GObject";
import { Text } from "gamedeck/lib/gobjects/Text";
import { Vector2 } from "gamedeck/lib/Utils";
import { Ellipse } from "gamedeck/lib/assets/Ellipse";
import { Node, NodeType } from "../graph/Node";
import { Graph } from "../graph/Graph";
import { Edge } from "../graph/Edge";

const DELIVERATOR_SIZE = 20;

export class DeliveratorState {
  position: Vector2;
  id: string;
  path: Node[];
  segmentProgress = 0;
  graph: Graph;
  segment?: [Node, Node];

  start: Node;
  restaurant: Node;
  end: Node;

  edges: Edge[];

  color = "hsla(" + Math.random() * 360 + ", 100%, 50%, 1)";

  constructor(props: { position: Vector2; id: string; graph: Graph }) {
    this.position = props.position;
    this.id = props.id;
    this.graph = props.graph;

    this.start = this.graph.getRandomNode(NodeType.NORMAL);
    this.restaurant = this.graph.getRandomNode(NodeType.RESTAURANT);
    this.end = this.graph.getRandomNode(NodeType.NORMAL);

    this.path = [
      ...this.graph.getPath(this.start.id, this.restaurant.id),
      ...this.graph.getPath(this.restaurant.id, this.end.id)
    ];

    this.edges = this.path
      .slice(0, this.path.length - 1)
      .map((n, i) => this.graph.getEdge(n.id, this.path[i + 1].id)!)
      .filter(edge => edge);

    if (this.path.length > 2) {
      this.segment = [this.path.shift()!, this.path.shift()!];
    }
  }

  startNewPath() {
    this.start = this.end || this.graph.getRandomNode(NodeType.NORMAL);
    this.restaurant = this.graph.getRandomNode(NodeType.RESTAURANT);
    this.end = this.graph.getRandomNode(NodeType.NORMAL);

    this.path = [
      ...this.graph.getPath(this.start.id, this.restaurant.id),
      ...this.graph.getPath(this.restaurant.id, this.end.id)
    ];

    this.edges = this.path
      .slice(0, this.path.length - 1)
      .map((n, i) => this.graph.getEdge(n.id, this.path[i + 1].id)!)
      .filter(edge => edge);
  }

  get distance() {
    return this.edges.reduce((accum, curr) => {
      const {start, end} = curr;

      if (start && end) {
        return accum + (this.graph.getDistance(start, end) || 0);
      } else {
        return accum;
      }
    }, 0);
  }
}

export class Deliverator extends GObject {
  sprite: Ellipse;

  constructor(state: DeliveratorState) {
    super({
      position: state.position.add(
        new Vector2(-DELIVERATOR_SIZE / 2, -DELIVERATOR_SIZE / 2)
      ),
      dimensions: new Vector2(DELIVERATOR_SIZE, DELIVERATOR_SIZE),
      id: state.id,
    });
    this.sprite = new Ellipse(
      DELIVERATOR_SIZE / 2,
      DELIVERATOR_SIZE / 2,
      state.color
    );
  }

  build() {
    return [
      new Text({
        text: `D${this.id}`,
        color: "black",
        font: "10px sans-serif",
        position: new Vector2(
          DELIVERATOR_SIZE / 2,
          (DELIVERATOR_SIZE / 4) * 2.5
        ),
        positioning: "middle center",
      }),
    ];
  }
}
