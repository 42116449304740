export class Edge {
  constructor(
    public id: string,
    public start: string,
    public end: string,
    public waypointFor: string,
    public highlight?: string
  ) {}

  projection() {
    return [this.start, this.end].sort().join("_");
  }
}
