import React from 'react';
import { DeliveratorState } from "../gobjects/Deliverator.go";
import { inconvenience } from "../inconvenience";
import { Graph } from "../graph/Graph";
import { Panel, Title, Detail } from './common';

interface Props {
  deliverator: DeliveratorState;
  graph: Graph;
}

export function DeliveratorPanel(props: Props) {

  const {deliverator, graph} = props;

  return <Panel>
    <Title color={deliverator.color}>Deliverator {deliverator.id}</Title>
    <Detail>
      <span>{deliverator.start.label}</span>
      <span>→</span>
      <span>{deliverator.restaurant.label}</span>
      <span>→</span>
      <span>{deliverator.end.label}</span>
    </Detail>
    {
      graph &&
      <Detail>
        Inconvenience:{" "}
        {inconvenience(deliverator, graph)}
      </Detail>
    }
  </Panel>
}
