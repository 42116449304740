type MetricFunction<T> = (val: T) => number;

export class MinHeap<T> {
  private heap: T[] = [];
  private set = new Set<T>();

  constructor(private metric: MetricFunction<T>) {}

  private getParent(index: number) {
    return Math.floor((index - 1) / 2);
  }

  private getLeftChild(index: number) {
    return 2 * index + 1;
  }

  private getRightChild(index: number) {
    return 2 * index + 2;
  }

  private val(index: number) {
    return this.heap[index] !== undefined
      ? this.metric(this.heap[index])
      : Infinity;
  }

  private swap(i: number, j: number) {
    [this.heap[i], this.heap[j]] = [this.heap[j], this.heap[i]];
  }

  private heapify_down(start = 0) {
    let current = start;
    while (
      this.val(current) > this.val(this.getLeftChild(current)) ||
      this.val(current) > this.val(this.getRightChild(current))
    ) {
      const smaller =
        this.val(this.getLeftChild(current)) <=
        this.val(this.getRightChild(current))
          ? this.getLeftChild(current)
          : this.getRightChild(current);

      this.swap(current, smaller);
      current = smaller;
    }
  }

  private heapify_up(start = this.size - 1) {
    if (this.heap.length > 0) {
      let current = start;

      while (
        current > 0 &&
        this.val(this.getParent(current)) > this.val(current)
      ) {
        this.swap(this.getParent(current), current);

        current = this.getParent(current);
      }
    }
  }

  add(element: T) {
    if (!this.set.has(element)) {
      this.heap.push(element);
      this.heapify_up();
      this.set.add(element);
    } else {
      this.heapify_up(this.heap.indexOf(element));
    }
  }

  pop() {
    if (this.size < 3) {
      return this.heap.shift();
    }

    const smallest = this.heap[0];
    this.heap[0] = this.heap.pop()!;

    this.heapify_down();

    this.set.delete(smallest);

    return smallest;
  }

  get head() {
    return this.heap[0];
  }

  get tail() {
    return this.heap[this.heap.length - 1];
  }

  get size() {
    return this.heap.length;
  }

  *[Symbol.iterator]() {
    while (this.head) {
      yield this.pop();
    }
  }

  get arr() {
    return Array.from(this);
  }
}
