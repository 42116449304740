import { Observable } from "../Observable";
import { useEffect, useState } from "react";

export default function useObservable<T>(observable: Observable<T>) {
  const [state, setState] = useState(observable.value);

  useEffect(() => {
    const subscription = observable.subscribe((data) => setState(data));

    return () => subscription.unsubscribe();
  }, [observable]);

  return state;
}
