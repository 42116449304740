import { GObject } from "gamedeck/lib/GObject";
import { Edge } from "../graph/Edge";
import { Graph } from "../graph/Graph";
import { Line } from "./Line.asset";

interface EdgeGraphicProps {
  graph: Graph;
  edge: Edge;
}

export class EdgeGraphic extends GObject {
  constructor({ graph, edge }: EdgeGraphicProps) {
    super({});

    const { start, end } = graph.getNodes(edge);

    this.sprite = new Line({
      color: edge.highlight || "grey",
      width: 10,
      start: start?.position!,
      end: end?.position!,
    });
  }
}
