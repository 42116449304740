import React from "react";
import useObservable from "../data/hooks/useObservable";
import deliveratorObservable from "../data/deliveratorObservable";
import { DeliveratorPanel } from "./DeliveratorPanel";
import graphObservable from "../data/graphObservable";
import { findLongestPaths } from "../findLongestPaths";
import { PathPanel } from "./PathPanel";
import { DeliveratorState } from "../gobjects/Deliverator.go";

function ControlPanel() {
  const deliverators = useObservable(deliveratorObservable);
  const graph = useObservable(graphObservable);

  if (!deliverators) {
    return <em>Loading...</em>;
  }

  const longestPaths = findLongestPaths(deliverators.slice());

  return <div>
    <h2>Longest Paths</h2>
    {longestPaths.map((deliverator, i) =>
      graph && deliverator instanceof DeliveratorState && <PathPanel deliverator={deliverator} key={i} />
    )}
    <h2>Deliverators</h2>
    {deliverators.map((deliverator, i) =>
      graph && <DeliveratorPanel graph={graph} deliverator={deliverator} key={i} />
    )}
  </div>;
}

export default ControlPanel;
