import { Graph } from "./graph/Graph";
import { PriorityQueue } from "./util/PriorityQueue";

export function dfs(src: string, dest: string, graph: Graph): string[] {
  const path: string[] = [];
  const visited = new Set([src]);

  dfsRecurse(src, dest, path, visited, graph);

  return path;
}

function dfsRecurse(
  src: string,
  dest: string,
  path: string[],
  visited: Set<string>,
  graph: Graph
): boolean {
  visited.add(src);

  path.push(src);

  if (src === dest) return true;

  if (
    graph.getNeighbors(src)
      .filter((neighbor) => !visited.has(neighbor))
      .map((neighbor) => dfsRecurse(neighbor, dest, path, visited, graph))
      .includes(true)
  )
    return true;
  else {
    path.pop();
    return false;
  }
}

export function bfs(src: string, dest: string, graph: Graph) {
  const queue = [[src]];

  let i = 0;
  const visited = new Set<string>();

  while (
    queue.length > 0 &&
    queue.every((path) => path[0] !== dest) &&
    i < 800
    ) {
    const path = queue.shift()!;
    const neighbors = graph.getNeighbors(path[0]);

    for (let neighbor of neighbors.filter((n) => !visited.has(n))) {
      queue.push([neighbor, ...path]);
      visited.add(neighbor)
    }

    i++;
  }

  const path = queue.find((path) => path[0] === dest);

  return path ? path.reverse() : [];
}

export function dijkstra(src: string, dest: string, graph: Graph) {
  const frontier = new PriorityQueue<string>(
    (node) => (cost.get(node) || 0)
  );
  frontier.enqueue(src);

  const from = new Map([[src, "root"]]);
  const cost = new Map([[src, 0]]);

  while (frontier.size > 0) {
    const current = frontier.dequeue()!;
    if (current === dest) break;

    for (let next of graph.getNeighbors(current)) {
      const newCost = cost.get(current)! + graph.getDistance(current, next)!;
      if (!cost.has(next) || cost.get(next)! >= newCost) {
        cost.set(next, newCost);
        frontier.enqueue(next);
        from.set(next, current);
      }
    }
  }

  const path: string[] = [];

  let current = dest;
  while (current !== "root") {
    path.push(current);
    current = from.get(current)!;
  }

  return path.reverse();
}

export function astar(src: string, dest: string, graph: Graph) {
  const from = new Map([[src, "root"]]);
  const cost = new Map([[src, 0]]);

  const frontier = new PriorityQueue<string>(
    (node) => (cost.get(node) || 0) + (graph.getDistance(node, dest) || 0)
  );
  frontier.enqueue(src);

  while (frontier.size > 0) {
    const current = frontier.dequeue()!;
    if (current === dest) break;

    for (let next of graph.getNeighbors(current)) {
      const newCost = cost.get(current)! + graph.getDistance(current, next)!;
      if (!cost.has(next) || cost.get(next)! >= newCost) {
        cost.set(next, newCost);
        frontier.enqueue(next);
        from.set(next, current);
      }
    }
  }

  const path: string[] = [];

  let current = dest;
  while (current !== "root") {
    path.push(current);
    current = from.get(current)!;
  }

  return path.reverse();
}
