import { GObject } from "gamedeck/lib/GObject";
import { Node } from "../graph/Node";

export class NodeGraphic extends GObject {
  constructor(private node: Node) {
    super({
      position: node.getPositioning(),
    });

    this.sprite = node.getSprite();
  }

  build() {
    return this.node.getLabel();
  }
}
