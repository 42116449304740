type callback<T> = (data: T) => void;

class Subscription<T> {
  constructor(private o: Observable<T>, private callback: callback<T>) {
  }
  
  publish(data: T) {
    this.callback(data);
  }

  unsubscribe() {
    this.o.unsubscribe(this);
  }
}

export class Observable<T> {
  observers: Subscription<T>[] = [];
  value?: T;

  constructor(initialData?: T) {
    this.value = initialData;
  }

  subscribe(callback: callback<T>) {
    const sub: Subscription<T> = new Subscription(this, callback);
    this.observers.push(sub);
    return sub;
  }

  unsubscribe(sub: Subscription<T>) {
    const i = this.observers.indexOf(sub);
    if (i >= 0) {
      this.observers.splice(i, 1);
    }
  }

  publish(data: T) {
    this.observers.forEach(o => o.publish(data));
    this.value = data;
  }
}
