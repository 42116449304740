import { Vector2 } from "gamedeck/lib/Utils";
import { Edge } from "../graph/Edge";
import { Graph } from "../graph/Graph";
import { Node, NodeType } from "../graph/Node";

interface GraphEdge {
  id: string;
  from: number;
  to: number;
  length: number;
  waypointEdges: string[];
  graphEdge: string;
}

interface DisplayEdge {
  id: string;
  from: number;
  to: number;
  length: number;
  waypointEdges: string[];
  graphEdge: string;
}

interface DisplayNode {
  id: number;
  label: string;
  x: number;
  y: number;
  group: "primary" | "restaurant" | "waypoint";
  graphEdge: string;
}

interface GraphNode {
  id: number;
  label: string;
  x: number;
  y: number;
  group: "primary" | "restaurant" | "waypoint";
  graphEdge: string;
}

interface Data {
  graphEdges: {
    [id: string]: GraphEdge;
  };
  graphNodes: {
    [id: string]: GraphNode;
  };
  displayEdges: DisplayEdge[];
  displayNodes: DisplayNode[];
}

const SCALE_X = 0.4;
const SCALE_Y = 0.4;
const TRANSLATE_X = 600;
const TRANSLATE_Y = 450;

export async function loadMap(url: string) {
  const edges: Edge[] = [];
  const nodes: Node[] = [];

  const data = (await fetch(url).then((res) => res.json())) as Data;

  for (let edge of data.displayEdges) {
    const e = new Edge(
      edge.id,
      edge.from.toString(),
      edge.to.toString(),
      edge.graphEdge
    );
    edges.push(e);
  }

  for (let node of data.displayNodes) {
    const group =
      node.group === "primary"
        ? NodeType.NORMAL
        : node.group === "restaurant"
        ? NodeType.RESTAURANT
        : NodeType.WAYPOINT;
    const n = new Node(
      node.id.toString(),
      new Vector2(
        node.x * SCALE_X + TRANSLATE_X,
        node.y * SCALE_Y + TRANSLATE_Y
      ),
      node.label,
      group
    );
    nodes.push(n);
  }

  return new Graph(nodes, edges);
}
