import { GObject } from "gamedeck/lib/GObject";
import { Edge } from "../graph/Edge";
import { Graph } from "../graph/Graph";
import { EdgeGraphic } from "./EdgeGraphic.go";
import { NodeGraphic } from "./NodeGraphic.go";

export class MapState {
  constructor(public highlightedEdges: Edge[]) {}
}

interface MapProps {
  state: MapState;
  graph: Graph;
}

export class Map extends GObject {
  constructor(private props: MapProps) {
    super({});
  }

  build() {
    const { graph } = this.props;

    return [
      ...graph.edges.map((edge) => new EdgeGraphic({ graph, edge })),
      ...graph.nodes.map((node) => new NodeGraphic(node)),
    ];
  }
}
