import { range } from "./util/range";
import { DeliveratorState } from "./gobjects/Deliverator.go";

type Comparator<T> = (a: T, b: T) => number;

export function findLongestPaths(deliverators: DeliveratorState[]) {
  const comparator: Comparator<DeliveratorState> =
    (a, b) => b.distance - a.distance;

  return range(1, 5).arr().map(i => nthSmallest(i, deliverators, comparator));
}

function nthSmallest<T>(n: number, arr: T[], comparator: Comparator<T>) {
  return qs(arr, 0, arr.length - 1, n, comparator);
}

function partition<T>(arr: T[], l: number, r: number, comparator: Comparator<T>) {
  const x = arr[r];
  let i = l;

  for (let j of range(l, r)) {
    if (comparator(arr[j], x) <= 0) {
      [arr[i], arr[j]] = [arr[j], arr[i]];
      i += 1;
    }
  }

  [arr[i], arr[r]] = [arr[r], arr[i]];

  return i;
}

function qs<T>(arr: T[], l: number, r: number, k: number, comparator: Comparator<T>): boolean | T | null {
  if (k > 0 && k <= r - l + 1) {
    let index = partition(arr, l, r, comparator);

    if (index - l === k - 1)
      return arr[index];

    if (index - l > k - 1)
      return qs(arr, l, index - 1, k, comparator);

    return qs(arr, index + 1, r,
      k - index + l - 1, comparator)
  }

  return null;
}
