import { MinHeap } from "./MinHeap";

type PriorityFunction<T> = (value: T) => number;

export class PriorityQueue<T> {
  private heap: MinHeap<T>;

  constructor(pf: PriorityFunction<T>) {
    this.heap = new MinHeap<T>(pf);
  }

  get front() {
    return this.heap.head;
  }

  get rear() {
    return this.heap.tail;
  }

  get size() {
    return this.heap.size;
  }

  enqueue(element: T) {
    return this.heap.add(element);
  }

  dequeue() {
    return this.heap.pop();
  }
}
