import { Edge } from "./Edge";
import { Node, NodeType } from "./Node";
import { astar } from "../pathfinding";
import { Vector2 } from "gamedeck/lib/Utils";

export class Graph {
  private nodeMap: Map<string, Node>;
  private edgeMap: Map<string, Edge>;

  constructor(public nodes: Node[], public edges: Edge[]) {
    this.nodeMap = new Map(nodes.map((node) => [node.id, node]));
    this.edgeMap = new Map(edges.map((edge) => [edge.projection(), edge]));
  }

  getNode(id: string) {
    return this.nodeMap.get(id);
  }

  getNodes(edge: Edge) {
    return {
      start: this.getNode(edge.start),
      end: this.getNode(edge.end),
    };
  }

  getNeighbors(node: string) {
    return this.edges.reduce<string[]>(
      (neighbors, edge) =>
        edge.end === node
          ? neighbors.includes(edge.start)
            ? neighbors
            : [...neighbors, edge.start]
          : edge.start === node
          ? neighbors.includes(edge.end)
            ? neighbors
            : [...neighbors, edge.end]
          : neighbors,
      []
    );
  }

  getPath(src: string, dest: string): Node[] {
    return astar(src, dest, this).map(n => this.getNode(n)!);
  }

  getRandomNode(type: NodeType) {
    return selectRandom(this.nodes.filter(node => node.group === type));
  }

  getEdge(node1: string, node2: string) {
    return this.edges.find(e => (e.start === node1 || e.start === node2) && (e.end === node1 || e.end === node2))
  }

  getDistance(node1: string, node2: string) {
    return (this.getNode(node1)?.position.add(this.getNode(node2)?.position.invert() || new Vector2(0, 0)).getMagnitude() || 0);
  }
}

function selectRandom<T>(arr: T[]) {
  const len = arr.length;
  return arr[Math.floor(Math.random() * len)];
}
