import { Asset } from "gamedeck/lib";
import { Vector2 } from "gamedeck/lib/Utils";
import { GObject } from "gamedeck/lib/GObject";

interface LineProps {
  color: string;
  width: number;
  start: Vector2;
  end: Vector2;
}

export class Line extends Asset {
  constructor(private props: LineProps) {
    super();
  }

  draw(ctx: CanvasRenderingContext2D, owner: GObject) {
    const { color, width, start, end } = this.props;

    ctx.beginPath();
    ctx.lineWidth = width;
    ctx.strokeStyle = color;
    ctx.moveTo(start.x + owner.position.x, start.y + owner.position.y);
    ctx.lineTo(end.x + owner.position.x, end.y + owner.position.y);
    ctx.stroke();
  }
}
